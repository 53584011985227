import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import iKizmetLogo from "./images/ikizmet-md.png";
import { useFormik } from "formik";
import AuthService from "./AuthService";
import "./assets/styles/normalize.css";
import "./App.scss";

function App() {
  const [info, setInfo] = useState({
    status: "idle",
    data: null,
    message: {},
  });

  const [forgot, setForgot] = useState(false);

  let auth = useRef(null);

  if (auth.current == null) auth.current = new AuthService();

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async () => {
    setInfo({ status: "pending", data: null });
    if (forgot) {
      let message = await auth.current.reset({
        email: formik.values.email,
      });

      setInfo({
        status: "idle",
        message: {
          type: "secondary",
          text: message,
        },
      });
      setForgot(false);
    } else {
      let res = await auth.current.login({
        email: formik.values.email,
        password: formik.values.password,
      });

      if (res.length === 1) {
        window.location.href = res[0].url;
      } else if (res.length === 0) {
        setInfo({
          status: "error",
          message: {
            type: "danger",
            text: "Invalid email or password",
          },
        });
      } else {
        setInfo({
          status: "authenticated",
          data: res,
          message: { type: "success", text: "Signed in successfully" },
        });
      }
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email address is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.password && !forgot) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const onExited = () => {
    setInfo((prev) => {
      return {
        ...prev,
        message: {},
      };
    });
  };

  return (
    <>
      <div className="alert__wrapper">
        <CustomAlert message={info.message} onExited={onExited} />
      </div>
      <div id="app">
        <div className="signin">
          <div className="middle">
            <div className="inner">
              <div className="mbl">
                <img src={iKizmetLogo} alt="logo" className="logo" />
              </div>
              <div className="form-inner">
                {info.status !== "authenticated" ? (
                  <form onSubmit={formik.handleSubmit}>
                    <div>
                      <div className="form-inner__input">
                        <input
                          placeholder="Email"
                          className="session-field email"
                          autoFocus="autofocus"
                          type="text"
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          autoComplete="email"
                        />

                        <div className="error">
                          {formik.errors.email
                            ? "* " + formik.errors.email
                            : ""}
                        </div>
                      </div>
                      {forgot ? null : (
                        <div className="form-inner__input">
                          <input
                            placeholder="Password"
                            className="session-field password"
                            type="password"
                            name="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            autoComplete="current-password"
                          />

                          <div className="error">
                            {formik.errors.password
                              ? "* " + formik.errors.password
                              : ""}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mtm">
                      <button
                        className="btn-session"
                        type="submit"
                        disabled={info.status === "pending"}
                      >
                        {forgot ? "Reset Password" : "LOG-IN "}
                      </button>

                      <div
                        className="forgot"
                        onClick={() => setForgot(!forgot)}
                      >
                        {forgot ? "Back to login" : " Forgot Password?"}
                      </div>
                    </div>
                  </form>
                ) : info.status === "authenticated" ? (
                  <List data={info.data} />
                ) : (
                  ""
                )}
              </div>
              <p className="signin-legal">
                For more info about iKizmet
                <br />
                <a
                  rel="noreferrer"
                  className="no-decoration"
                  target="_blank"
                  href="https://www.clubready.club/fitness-business-analytics"
                >
                  Visit Our Marketing Site
                </a>
              </p>

              <p className="signin-legal">
                By using this site you agree to the
                <br />
                <a
                  rel="noreferrer"
                  className="no-decoration"
                  target="_blank"
                  href="/privacy.html"
                >
                  Privacy Policy
                </a>
                {" and "}
                <a
                  rel="noreferrer"
                  className="no-decoration"
                  target="_blank"
                  href="/tos.html"
                >
                  Terms of Service
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const List = ({ data }) => {
  return (
    <ul className="list">
      {data &&
        data.map((env, ind) => {
          return env ? (
            <li key={ind} className="list__item">
              <a href={env.url}>{env.name}</a>
            </li>
          ) : (
            ""
          );
        })}
    </ul>
  );
};

const CustomAlert = ({ message = {}, onExited }) => {
  const [showAlert, setShowAlert] = useState(false);
  const ref = useRef(null);

  const fadeOut = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    if (message.text) {
      setShowAlert(true);
    }
  }, [message.text]);

  let className = ["success", "danger", "secondary"].includes(message?.type)
    ? "alert-" + message?.type
    : "";

  return (
    <CSSTransition
      in={showAlert}
      onEntered={fadeOut}
      unmountOnExit
      classNames="alert"
      nodeRef={ref}
      timeout={3000}
      onExited={onExited}
    >
      <div ref={ref} className={"alert " + className}>
        {message?.text}
      </div>
    </CSSTransition>
  );
};

export default App;
