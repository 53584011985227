import axios from "axios";

const FALLBACK_ENVIRONMENTS = [
  "https://qa.ikizmet.com",
  "https://qa-ikizmet.clubready.com",
  "https://reporting-qa.ikizmet.com",
  "https://demo-ikizmet.clubready.com",
  "https://barre3.ikizmet.com",
  "https://dashboard.ikizmet.com",
  "https://marianatek.ikizmet.com",
  "https://ikizmet.clubready.com",
  "https://reporting-clubready.ikizmet.com",
  "https://xpo-ikizmet.clubready.com",
  "https://reporting-xpo.ikizmet.com",
  "https://fitlab-ikizmet.clubready.com",
  "https://reporting-fitlab.ikizmet.com"
];

class AuthService {
  constructor() {
    this.environments = [];
    this.loadEnvs();
  }

  async loadEnvs() {
    try {
      let { data } = await axios.get("./environments.json");
      this.environments = data;
    } catch (err) {
      console.log(err);
      this.environments = FALLBACK_ENVIRONMENTS;
    }
  }

  async login(params) {
    let res = await axios
      .get("https://api.ipify.org", { timeout: 5000 })
      .catch((err) => {
        console.log(err);
      });

    let ip = res ? res.data : "";

    const getEnv = (baseURL, params) => {
      return axios.post(
        "/api/v2/base/login_with_credentials",
        { ...params, ip },
        { baseURL, timeout: 30000 }
      );
    };

    const promises = this.environments.map((host) => getEnv(host, params));

    let all = await Promise.allSettled(promises);

    let fulfilledRes = all.filter(({ status }) => status === "fulfilled");

    let data = fulfilledRes.map((res) => {
      let baseURL = res.value.config.baseURL;
      let token = res.value.data.token;
      let email = encodeURIComponent(params.email);

      let url =
        baseURL +
        "/user_sessions/login_by_token?user_session[token]=" +
        token +
        "&user_session[email]=" +
        email;

      let name = baseURL.replace("https://", "");

      return { name, url };
    });

    return data;
  }

  async reset(params) {
    const promises = this.environments.map((host) =>
      axios.post(
        "/api/v2/base/reset_password",
        { ...params },
        { baseURL: host }
      )
    );

    let all = await Promise.allSettled(promises);
    let fulfilledRes = all.filter(({ status }) => status === "fulfilled");

    let message = "";

    for (let res of fulfilledRes) {
      if (res.value.data.message === "Email was sent") {
        message = "Email was sent";
        break;
      }
    }

    if (message) return message;

    return fulfilledRes[0].value.data.message;
  }
}

export default AuthService;
